import Logo from './logo.png';
import Banner from './banner.png';

export default {
  logo: Logo,
  title: 'Actividades de voluntariado',
  logo_width: '150px',
  banner: Banner,
  name: 'Bisblick',
};
